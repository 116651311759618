exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-dashboard-page-js": () => import("./../../../src/pages/dashboardPage.js" /* webpackChunkName: "component---src-pages-dashboard-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-proto-page-js": () => import("./../../../src/pages/MapProtoPage.js" /* webpackChunkName: "component---src-pages-map-proto-page-js" */),
  "component---src-pages-profile-page-js": () => import("./../../../src/pages/profilePage.js" /* webpackChunkName: "component---src-pages-profile-page-js" */),
  "component---src-pages-sign-in-page-js": () => import("./../../../src/pages/signInPage.js" /* webpackChunkName: "component---src-pages-sign-in-page-js" */),
  "component---src-pages-user-story-1-js": () => import("./../../../src/pages/userStory1.js" /* webpackChunkName: "component---src-pages-user-story-1-js" */),
  "component---src-pages-user-story-2-js": () => import("./../../../src/pages/userStory2.js" /* webpackChunkName: "component---src-pages-user-story-2-js" */)
}

